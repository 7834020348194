/* biscuits */
.image-container {
    width: 100%; /* Full width for responsiveness */
    height: 200px;
    /* overflow: hidden; 
    position: relative;  */
    border: 0.5px solid rgb(247, 245, 245);
   
  }
  
  .container{
    padding-left:10%;
  }
  .label{
    margin-right:25%;
  }
  H3 {
    margin-left: 25%;
  }