
/* //-----------------------carousel css-------------------// */
.carousel-img {
  height: 550px; /* Adjust this value as needed */
  object-fit: cover; /* This will maintain the aspect ratio */
  width:100%;
}

.image-container {
  position: relative;
  overflow: hidden; /* Ensures any overflow is hidden */
  width: 100%;
  background-size: cover;
}

.image {
  opacity: 0; /* Start hidden */
  transform: translateY(60px); /* Start slightly below */
  transition: opacity 1s ease-out, transform 1s ease-out; /* Transition effect */
  height: 500px;
  width:400px;
}

.image.visible {
  opacity: 1; /* Show the image */
  transform: translateY(0); /* Move to original position */
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white; /* Text color */
  opacity: 0; /* Start hidden */
  transition: opacity 0.3s ease; /* Transition effect */
}

.image-container:hover .image-overlay {
  opacity: 1; /* Show on hover */
}

.carousel-image-container {
  display: flex;
  justify-content: space-around; /* Centers images in the row */
  margin-bottom: 10px; /* Space between rows */
}

.carousel-image {
  max-width: 10%; /* Allow the image to take full width of its container */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensure the image fits within its container without cropping */
  margin: 0 10px; /* Space between images */
}



/* //---------------------------card css---------------------------// */
.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  transition: opacity 0.3s ease; /* Smooth transition */
  pointer-events: none; /* Allows interactions with the card content */
}

.card {
  width: 300px; /* Set desired width */
  height: 400px; /* Set desired height */
}

.card {
  background-color: #fff; /* Change to desired color */
  transition: background-color 0.3s ease; /* Optional: for smooth transition */
}

.card:hover {
  background-color: #413c3c; /* Change to desired hover color */
}

.card-container {
  display: flex;
  justify-content: space-between; /* Adjust as necessary */
  flex-wrap: wrap;
}

.card-image {
  width: 100%; /* Full width of the card */
  height: 500px; /* Fixed height, adjust as needed */
  object-fit: cover; /* Ensures the image covers the area without distortion */
}
.card-text {
  color: #6b6767; /* Change text color */
  font-size: 1.5em; /* Change font size */
  text-align: center; /* Center align the text */
  font-weight: bold;
}

.float-up {
  opacity: 0; /* Start invisible */
  transform: translateY(20px); /* Start slightly below */
  transition: opacity 0.5s ease-out, transform 0.5s ease-out; /* Smooth transition */
}

.image-container:hover .float-up {
  opacity: 1; /* Fade in */
  transform: translateY(0); /* Move to original position */
}

.benefit-heading {
  position: relative;
  margin-top:-350px;

}

.benefit-text {
  position: absolute;
  margin-top:-250px; 
  margin-left: 30px;
  justify-content: left;
}
.benefits_key{
  margin-left:100px;
  margin-top:-200px;

}
.text_key{
  margin-left: 30px;
  justify-content: center;
  margin-left: 30px;
  justify-content: left;

}



/* //-----------------------about_us----------------------// */

h1.about_us{
  width: 100%;
  height: 100px;
  background-image: url(images/spices.jpg);
  background-size: cover;
  font-size: 80px;
  font-weight: 600;
  padding-top:10px;
  color: #fff;
}
.product_container
{
  background-image: url(images/dark.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 700px; */
}
.product{
  color: #f7f8f8;
  font-size: 80px;
  font-weight: 600;
  margin-bottom: 30px;
  margin-top: 10px;
}
h1 
{
  font-weight: 600;
}
H4 {
  font-weight: 600;
}






